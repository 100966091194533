import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';
import { ReviewView } from '@zola/svc-marketplace-ts-types';

import dynamic from 'next/dynamic';

import { MappedReviewView } from '~/types/responseTypes';

/**
 * Dynamically load either the mobile or desktop lightbox
 */
export const Lightbox = ({
  photoUuids,
  initialUuid,
  onClose,
  reviews,
  showMobileFooter,
  disableControls = false,
}: {
  photoUuids: string[];
  initialUuid: string;
  onClose: () => void;
  reviews?: MappedReviewView[] | ReviewView[];
  showMobileFooter?: boolean;
  disableControls?: boolean;
}) => {
  if (isMobile()) {
    const MobileLightbox = dynamic(() => import('../../ui/CoverGalleryV2/MobileLightbox'), {
      ssr: false,
    });
    return (
      <MobileLightbox
        photoUuids={photoUuids}
        initialUuid={initialUuid}
        onClose={onClose}
        reviews={reviews}
        showFooter={showMobileFooter}
        disableControls={disableControls}
      />
    );
  }
  const NotMobileLightbox = dynamic(() => import('../../ui/CoverGalleryV2/NotMobileLightbox'), {
    ssr: false,
  });
  return (
    <NotMobileLightbox
      photoUuids={photoUuids}
      initialUuid={initialUuid}
      onClose={onClose}
      reviews={reviews}
      disableControls={disableControls}
    />
  );
};

export default Lightbox;
