import cx from 'classnames';

import Arrow from '~/assets/images/doodle/arrow-pink.svg';
import './listingExample.less';

type ListingExampleProps = {
  alt?: string;
  example1x?: string;
  example2x?: string;
  size?: 'sm' | 'lg';
};
/**
 * Displays an example of what a completed part of the listing looks like
 * with red arrow text, example, pointing to it.
 *
 * @param {*} props
 */
const ListingExample = (props: ListingExampleProps) => {
  const { alt = '', example1x, example2x, size = 'lg' } = props;
  const classes = cx(
    'listing__example',
    size === 'sm' ? 'listing__example-small' : 'listing__example-large'
  );

  return (
    <div className={classes}>
      <div className="listing__example-text-container">
        <div className="listing__example-text">
          <img src={Arrow} alt="" className="listing__example-arrow" />
          example
        </div>
      </div>
      {/* If you wrap this in lazy load, you'll need to figure out why when hard-refreshing the listing, then going to rooms, the image doesn't display */}
      <img
        src={example1x}
        srcSet={`${example1x} 1x, ${example2x} 2x`}
        alt={alt}
        className="listing__example-image"
      />
    </div>
  );
};

export default ListingExample;
