import { useCallback, useMemo, useState } from 'react';

import { VENUES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';
import { StorefrontFirstMoveView } from '@zola/svc-marketplace-ts-types';

import Lightbox from '~/components/common/ui/CoverGalleryV2/Lightbox';
import LightboxV2 from '~/components/common/zolaUI/Lightbox/LightboxV2';
import { LightboxEntry } from '~/components/common/zolaUI/Lightbox/types';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';

import { LightboxContext } from './LightboxContext';
import { useStorefrontDetails } from './StorefrontDetailsContext';

const StorefrontDetailsLightboxHed =
  (storefrontDetails: CouplesStorefrontDetails, firstMove: StorefrontFirstMoveView | null) =>
  (isMobile: boolean) => {
    if (!isMobile && firstMove) {
      return `${storefrontDetails.name} has invited you to inquire`;
    }
    return storefrontDetails.name;
  };

export const StorefrontDetailsLightboxProvider: React.FC = ({ children }) => {
  const { storefrontDetails, firstMove, eventSpacesV2 } =
    useStorefrontDetails<CouplesStorefrontDetails>();

  const [selectedPhotoUuid, setSelectedPhotoUuid] = useState<string | undefined>();

  const entries: LightboxEntry[] = useMemo(() => {
    const { coverGallery = [], photoGallery } = storefrontDetails;

    const galleryPhotos: LightboxEntry[] = [...coverGallery, ...photoGallery].map((photo) => ({
      uuid: photo.uuid,
      hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
      group: {
        label: 'Vendor photos',
        key: 'vendor-photos',
      },
    }));

    if (eventSpacesV2 && storefrontDetails.taxonomyKey === VENUES_TAXONOMY_KEY) {
      const {
        venueDetails: { spaces },
      } = storefrontDetails;

      const photosFromSpaces: LightboxEntry[] = spaces.flatMap((space) => {
        const spaceGalleryPhotos = space.venueSpaceGalleryViews.map((photo) => ({
          uuid: photo.imageId,
          hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
          group: {
            label: 'Vendor photos',
            key: 'vendor-photos',
          },
        }));

        if (space.floorplanUuid) {
          spaceGalleryPhotos.push({
            uuid: space.floorplanUuid,
            hed: StorefrontDetailsLightboxHed(storefrontDetails, firstMove),
            group: {
              label: 'Vendor photos',
              key: 'vendor-photos',
            },
          });
        }
        return spaceGalleryPhotos;
      });

      return [...galleryPhotos, ...photosFromSpaces];
    }

    return galleryPhotos;
  }, [eventSpacesV2, firstMove, storefrontDetails]);

  const openLightbox = useCallback(
    (photoUuid: string) => {
      const foundEntry = entries.find(({ uuid }) => uuid === photoUuid);
      if (foundEntry) {
        setSelectedPhotoUuid(photoUuid);
      }
      // Should we show the first photo?
    },
    [entries]
  );

  const photoUuids = useMemo(() => entries.map((entry) => entry.uuid), [entries]);

  return (
    <LightboxContext.Provider value={{ openLightbox }}>
      {children}
      {selectedPhotoUuid && !eventSpacesV2 && (
        <Lightbox
          photoUuids={photoUuids}
          initialUuid={selectedPhotoUuid}
          onClose={() => setSelectedPhotoUuid(undefined)}
          showMobileFooter
        />
      )}
      {selectedPhotoUuid && eventSpacesV2 && (
        <LightboxV2
          entries={entries}
          initialUuid={selectedPhotoUuid}
          onClose={() => setSelectedPhotoUuid(undefined)}
          showMobileFooter
        />
      )}
    </LightboxContext.Provider>
  );
};
