import { useState, useRef, useCallback, useEffect, Fragment } from 'react';

import LoveBirds from '@zola/zola-ui/src/assets/images/icons/love_birds.svg';
import { ChevronButton } from '@zola/zola-ui/src/components/ChevronButton';

import CachedImage from '~/components/common/images/CachedImage';
import { LEFT_ARROW, RIGHT_ARROW } from '~/components/common/ui/constants';
import PhotoCredit from '~/components/common/ui/PhotoCredit/PhotoCredit';
import { MappedGalleryPhotoView } from '~/types/responseTypes';
import { TrackingProductLocation } from '~/util/trackingHelper';
import './GalleryModal.less';

export interface GalleryModalProps {
  photoGallery: MappedGalleryPhotoView[];
  index: number;
  hideModalFn: () => void;
}

const GalleryModal = (props: GalleryModalProps) => {
  const { photoGallery, index: photoIndex, hideModalFn } = props;
  const [index, setIndex] = useState(photoIndex);
  const photoContainerRef = useRef<HTMLDivElement>(null);
  const [photoLoaded, setPhotoLoaded] = useState(false);

  const setPhotoOrientation = () => {
    const photo = document.querySelector<HTMLElement>('.gallery-modal__photo');
    const photoWrapper = document.querySelector<HTMLElement>('.gallery-modal_photo-wrapper');
    const photoContainer = photoContainerRef.current;
    const { width, height } = photoGallery[index];
    const photoAspectRatio = width / height;
    const photoContainerAspectRatio =
      photoContainer && photoContainer.offsetWidth / photoContainer.offsetHeight;
    if (photoContainerAspectRatio && photoAspectRatio > photoContainerAspectRatio) {
      photo?.classList.remove('portrait');
      photo?.classList.add('landscape');
      photoWrapper?.classList.remove('portrait');
      photoWrapper?.classList.add('landscape');
    } else {
      photo?.classList.remove('landscape');
      photo?.classList.add('portrait');
      photoWrapper?.classList.remove('landscape');
      photoWrapper?.classList.add('portrait');
    }
  };

  const setPhotoOpacity = (opacity: number) => {
    const photo = document.querySelector<HTMLElement>('.gallery-modal__photo');
    if (photo) {
      photo.style.opacity = opacity.toString();
    }
  };

  const handlePhotoOnLoad = () => {
    setPhotoOrientation();
    setPhotoOpacity(1.0);
    setPhotoLoaded(true);
  };

  const previous = useCallback(() => {
    setPhotoOpacity(0);
    if (index === 0) {
      setIndex(photoGallery.length - 1);
    } else {
      setIndex(index - 1);
    }
  }, [index, photoGallery.length]);

  const next = useCallback(() => {
    setPhotoOpacity(0);
    if (index === photoGallery.length - 1) {
      setIndex(0);
    } else {
      setIndex(index + 1);
    }
  }, [index, photoGallery.length]);

  const handleKeyDown = useCallback(
    (event) => {
      if (event.keyCode === LEFT_ARROW) {
        previous();
      } else if (event.keyCode === RIGHT_ARROW) {
        next();
      }
      event.stopPropagation();
    },
    [previous, next]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const {
    uuid,
    photoCreditName: photographerName,
    photoCreditReferenceVendorSlug: photographerSlug,
  } = photoGallery[index];

  return (
    <div className="marketplace__storefront marketplace__gallery-modal">
      <div className="gallery-modal__photo-container" ref={photoContainerRef}>
        <figure className="gallery-modal__photo-figure">
          <img className="gallery-modal__icon" src={LoveBirds} alt="" />
          <div className="gallery-modal_photo-wrapper">
            <CachedImage
              className="gallery-modal__photo landscape"
              uuid={uuid}
              srcSizes={[
                { width: 500, height: 375 },
                { width: 1000, height: 750 },
                { width: 2000, height: 1500 },
              ]}
              sizes="(min-width: 992px) 70vw, 100vw"
              onLoad={handlePhotoOnLoad}
            />
            {photoLoaded && (
              <PhotoCredit
                location={TrackingProductLocation.STOREFRONT_DETAIL_PAGE}
                position={1}
                section="GALLERY_MODAL"
                photographerName={photographerName || ''}
                photographerSlug={photographerSlug || ''}
              />
            )}
          </div>
        </figure>
      </div>
      {photoGallery.length > 1 && (
        <Fragment>
          <ChevronButton
            className="gallery-modal__previous"
            direction="left"
            ariaLabel="Previous"
            onClick={previous}
          />
          <ChevronButton
            className="gallery-modal__next"
            direction="right"
            ariaLabel="Next"
            onClick={next}
          />
        </Fragment>
      )}
      <a className="gallery-modal__close" onClick={hideModalFn}>
        <span className="zola-ui-icon-close" />
      </a>
    </div>
  );
};

export default GalleryModal;
