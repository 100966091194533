import { Fragment, useCallback, useState } from 'react';

import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';
import { ReviewView } from '@zola/svc-marketplace-ts-types';
import { PhotoIcon } from '@zola/zola-ui/src/components/SvgIconsV3/Photo';
import COLORS from '@zola/zola-ui/src/styles/emotion/colors3';

import MobileLightbox from '~/components/common/ui/CoverGalleryV2/MobileLightbox';
import NotMobileLightbox from '~/components/common/ui/CoverGalleryV2/NotMobileLightbox';
import { MappedReviewView } from '~/types/responseTypes';

import {
  PhotoCountMobile,
  PhotoCountDesktop,
  PhotoIconAndCount,
  PhotoPreview,
  PhotoPreviewOverlay,
  PhotoPreviewOverlayWrapper,
  ReviewsSectionGalleryWrapper,
  StyledCachedImage,
} from './ReviewsSectionGallery.styles';

const getLightboxPhotoUuids = ({ photoUuids, reviews }: ReviewsSectionGalleryProps) => {
  const reviewPhotoUuids = reviews?.flatMap((review) => review.reviewImageIds || []) || [];
  const uniquePhotoUuids = new Set([...reviewPhotoUuids, ...photoUuids]);
  return [...uniquePhotoUuids];
};

type ReviewsSectionGalleryProps = {
  className?: string;
  photoUuids: string[];
  reviews?: MappedReviewView[] | ReviewView[];
  showMobileFooter?: boolean;
};

export const ReviewsSectionGallery = ({
  className,
  photoUuids,
  reviews,
  showMobileFooter = false,
}: ReviewsSectionGalleryProps): JSX.Element => {
  const [selectedUuid, setSelectedUuid] = useState<string | undefined>();
  const totalLength = photoUuids.length;

  const onClickImage = useCallback((uuid: string) => {
    setSelectedUuid(uuid);
  }, []);

  const onCloseLightbox = useCallback(() => {
    setSelectedUuid(undefined);
  }, []);

  if (totalLength === 0) {
    return <Fragment />;
  }

  return (
    <ReviewsSectionGalleryWrapper className={className}>
      {photoUuids.map((photoUuid, i) => {
        return (
          <PhotoPreview
            index={i}
            key={`reviews-section-gallery-${i}`}
            onClick={() => onClickImage(photoUuid)}
          >
            <StyledCachedImage uuid={photoUuid} />
            <PhotoPreviewOverlayWrapper index={i} totalLength={totalLength}>
              <PhotoPreviewOverlay />
              <PhotoIconAndCount>
                <PhotoIcon color={COLORS.WHITE_100} height={24} title="" width={24} />
                <PhotoCountMobile index={i} totalLength={totalLength}>
                  ({totalLength - 3}+)
                </PhotoCountMobile>
                <PhotoCountDesktop index={i} totalLength={totalLength}>
                  ({totalLength - 4}+)
                </PhotoCountDesktop>
              </PhotoIconAndCount>
            </PhotoPreviewOverlayWrapper>
          </PhotoPreview>
        );
      })}
      {selectedUuid && (
        <>
          {isMobile() ? (
            <MobileLightbox
              photoUuids={getLightboxPhotoUuids({ photoUuids, reviews })}
              initialUuid={selectedUuid}
              onClose={onCloseLightbox}
              reviews={reviews}
              showFooter={showMobileFooter}
            />
          ) : (
            <NotMobileLightbox
              photoUuids={getLightboxPhotoUuids({ photoUuids, reviews })}
              initialUuid={selectedUuid}
              onClose={onCloseLightbox}
              reviews={reviews}
            />
          )}
        </>
      )}
    </ReviewsSectionGalleryWrapper>
  );
};
