import { CSSProperties } from 'react';

import LazyLoad from 'react-lazyload';
import { ImageComponentProps } from 'react-photo-gallery';

import CachedImage from '~/components/common/images/CachedImage';
import './galleryPhoto.less';

export interface GalleryImage {
  width: number;
  height: number;
  src: string;
  key?: string;
  uuid?: string;
  id?: number;
  placeHolder?: boolean;
}

const GalleryPhoto = (
  props: ImageComponentProps<{ placeHolder?: boolean; overflow?: boolean }>
) => {
  const { margin, index, photo, direction, top, left, onClick } = props;
  const { key, width, height, placeHolder, overflow } = photo;
  const containerStyle: CSSProperties = { position: 'relative' };

  if (direction === 'column') {
    containerStyle.position = 'absolute';
    containerStyle.top = top;
    containerStyle.left = left;
  }

  return (
    <div
      className="gallery-photo"
      style={{ width, height, margin, ...containerStyle }}
      key={`gallery-photo-${key}`}
    >
      <LazyLoad height={height} offset={100} overflow={overflow} once>
        {placeHolder ? (
          <div className="gallery-photo__placeholder">
            <img src={photo.src} alt="" className="gallery-photo__img" />
          </div>
        ) : (
          <CachedImage
            className="gallery-photo__img"
            uuid={key}
            quality={60}
            srcSizes={[
              { width: 150 },
              { width: 200 },
              { width: 250 },
              { width: 300 },
              { width: 350 },
              { width: 400 },
              { width: 500 },
              { width: 600 },
              { width: 700 },
              { width: 800 },
              { width: 900 },
              { width: 1000 },
            ]}
            onClick={(e) => onClick && onClick(e, { index })}
          />
        )}
      </LazyLoad>
    </div>
  );
};

export default GalleryPhoto;
