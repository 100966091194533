import { Fragment } from 'react';

import _sortBy from 'lodash/sortBy';

import { CouplesStorefrontDetailsVenue } from '~/types/storefrontDetails';

import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import ShowcasesSection from '../components/ShowcasesSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';
import { EventSpaces } from './components/EventSpaces';
import MenusSection from './components/MenusSection';
import RequiredVendorsSection from './components/RequiredVendorsSection';
import SpaceDetail from './components/SpaceDetails';

const VenueDetails = () => {
  const { storefrontDetails, otherServices, eventSpacesV2 } =
    useStorefrontDetails<CouplesStorefrontDetailsVenue>();
  const { name, venueDetails, options, packages } = storefrontDetails;
  const { spaces } = venueDetails;
  const servicesOffered = filterForParentKey('services-offered', options);
  const transportationOffered = filterForParentKey('transportation-services', options);
  const rentalsOffered = filterForParentKey('rental-services', options);
  const accommodationsOffered = filterForParentKey('accommodations-services', options);
  const hasServices = [
    servicesOffered,
    transportationOffered,
    rentalsOffered,
    accommodationsOffered,
  ].some((services) => services.length > 0);

  const hasServiceOrPackageOrSpaces = hasServices || !!packages?.length || spaces;

  return (
    <Fragment>
      {hasServiceOrPackageOrSpaces && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {spaces && !eventSpacesV2 && (
            <ShowcasesSection
              showcases={_sortBy(spaces, ['displayOrder'])}
              component={SpaceDetail}
              heading="Our event spaces"
            />
          )}
          {eventSpacesV2 && <EventSpaces />}
          <PackagesSection />
          {hasServices && (
            <VendorStorefrontSection
              hed={`Available services that can be provided by ${name}`}
              className="container"
              id="services"
            >
              {serviceBlock(
                servicesOffered.length > 0,
                'Services & amenities',
                servicesOffered,
                true
              )}
              {serviceBlock(
                transportationOffered.length > 0,
                'Transportation & access',
                transportationOffered,
                true
              )}
              {serviceBlock(rentalsOffered.length > 0, 'Rentals', rentalsOffered, true)}
              {serviceBlock(
                accommodationsOffered.length > 0,
                'Accommodations',
                accommodationsOffered,
                true
              )}
            </VendorStorefrontSection>
          )}
          <MenusSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <RequiredVendorsSection />
      <OtherVendorsSection
        title={`Preferred vendors of ${name}`}
        vendors={storefrontDetails.preferredVendors}
      />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
    </Fragment>
  );
};

export default VenueDetails;
