import { WeddingCardPhotoView, WeddingCardView } from '@zola/svc-marketplace-ts-types';
import GeoPinIcon from '@zola/zola-ui/src/components/SvgIcons/GeoPin';

import _get from 'lodash/get';
import nanoid from 'nanoid';
import LazyLoad from 'react-lazyload';
import ReactToolTip from 'react-tooltip';

import CachedImage from '~/components/common/images/CachedImage';
import { titleCase } from '~/util/textUtils';

import './weddingCard.less';

const ColorSwatch = ({ hexCode, name }: { hexCode: string; name: string }) => {
  const swatchId = `color-swatch-${nanoid()}`;
  return (
    <div className="color-swatch-container">
      <ReactToolTip id={swatchId} aria-haspopup="true">
        {name}
      </ReactToolTip>
      <div
        className="color-swatch"
        style={{ backgroundColor: `${hexCode}` }}
        key={`${name}`}
        data-tip
        data-effect="solid"
        data-for={swatchId}
      />
    </div>
  );
};

export type WeddingCardProps = {
  wedding: WeddingCardView;
};

const WeddingCardPhoto = ({ photo }: { photo: WeddingCardPhotoView }): JSX.Element => {
  /* if photo is portrait, set fit to max */
  const { imageId } = photo;
  return (
    <div key={imageId} id={`photo-${imageId}`}>
      <div className="wedding-card__carousel-photo">
        <div>
          <LazyLoad height={460} offset={460} overflow>
            {/* Set width/height to same aspect ratio as the images */}
            <CachedImage
              className="wedding-card__carousel-photo-image"
              uuid={imageId as string}
              width={460}
              height={345}
              srcSizes={[
                { width: 200, height: 150 },
                { width: 250, height: 188 },
                { width: 300, height: 225 },
                { width: 400, height: 300 },
                { width: 460, height: 345 },
                { width: 500, height: 375 },
                { width: 600, height: 450 },
              ]}
              fit="crop"
              quality={60}
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

/**
 * An old version of real wedding cards used to show Real Weddings a vendor has been
 * tagged in on their listing page.
 */
const WeddingCard = ({ wedding }: WeddingCardProps) => {
  const { address, colors, photos, title } = wedding;
  const city = _get(address, 'city', '');
  const stateProvince = _get(address, 'stateProvince', '');

  const colorSwatches = colors
    .slice(0, 3)
    .map((color, index) => (
      <ColorSwatch
        key={`color-swatch-${index}`}
        hexCode={color.colorSwatch as string}
        name={color.name}
      />
    ));

  return (
    <div className="wedding-card">
      <div className="wedding-card-body">
        <div className="wedding-card__carousel-container">
          <WeddingCardPhoto photo={photos[0]} />
          <div className="wedding-card__carousel-wedding-title">{title}</div>
        </div>
      </div>
      <div className="wedding-card__footer">
        <div className="wedding-card__footer-location">
          {city && stateProvince ? (
            <p>
              <GeoPinIcon className="location-pin-icon" width={16} height={16} />
              {titleCase(city)}, {stateProvince}
            </p>
          ) : null}
        </div>
        <div className="wedding-card__footer-colors">{colorSwatches}</div>
      </div>
    </div>
  );
};

export default WeddingCard;
