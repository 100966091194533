import { createContext, useContext } from 'react';

export type LightboxContextValues = {
  openLightbox: (photoUuid: string) => void;
};

export const LightboxContext = createContext<LightboxContextValues | undefined>(undefined);

export const useLightboxContext = () => {
  const context = useContext(LightboxContext);
  if (context === undefined) {
    throw new Error('useLightboxContext must be used within a LightboxProvider');
  }
  return context;
};
