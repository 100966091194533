import { Fragment, useMemo } from 'react';

import { CtaType } from '@zola-helpers/client/dist/es/tracking/ctaEvents/ctaEvents';

import { trackCtaClicked, TrackingProductLocation } from '~/util/trackingHelper';

import styles from './photoCredit.module.less';

interface PhotoCreditProps {
  photographerName?: string;
  photographerSlug?: string;
  /** for event tracking */
  location?: TrackingProductLocation;
  /** for event tracking */
  position?: number;
  /** for event tracking */
  section?: string;
}

const PhotoCredit = ({
  photographerName,
  photographerSlug,
  location,
  position,
  section,
}: PhotoCreditProps): JSX.Element => {
  const path = useMemo(() => {
    return photographerSlug ? `/wedding-vendors/wedding-photographers/${photographerSlug}` : '';
  }, [photographerSlug]);

  const cta = {
    ctaId: photographerName,
    cta: path,
    location,
    ctaType: CtaType.LINK,
    position,
    section,
  };

  return (
    <Fragment>
      {photographerName && photographerSlug && (
        <a
          href={path}
          target="_blank"
          rel="noreferrer"
          className={styles.photoCredit}
          onClick={() => trackCtaClicked(cta)}
        >
          {photographerName}
        </a>
      )}
      {photographerName && !photographerSlug && (
        <span className={styles.photoCredit}>{photographerName}</span>
      )}
    </Fragment>
  );
};

export default PhotoCredit;
