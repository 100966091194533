import { Fragment } from 'react';

import { CouplesStorefrontDetailsBaker } from '~/types/storefrontDetails';

import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';

const BakerDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsBaker>();
  const { name, options, preferredVendors, packages } = storefrontDetails;
  const productsOffered = filterForParentKey('bakers-product-types', options);
  const decorationsOffered = filterForParentKey('bakers-decorations', options);
  const customizationsOffered = filterForParentKey('bakers-cake-customizations', options);
  const servicesOffered = filterForParentKey('bakers-services', options);
  const dietaryAccommodationsOffered = filterForParentKey('bakers-dietary-accommodations', options);
  const hasServices = [
    productsOffered,
    decorationsOffered,
    customizationsOffered,
    servicesOffered,
    dietaryAccommodationsOffered,
  ].some((services) => services.length > 0);

  const hasServiceOrPackage = hasServices || !!packages?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(productsOffered.length > 0, 'Dessert types', productsOffered, true)}
              {serviceBlock(
                decorationsOffered.length > 0,
                'Decor options',
                decorationsOffered,
                true
              )}
              {serviceBlock(
                customizationsOffered.length > 0,
                'Available customizations',
                customizationsOffered,
                true
              )}
              {serviceBlock(servicesOffered.length > 0, 'Services offered', servicesOffered, true)}
              {serviceBlock(
                dietaryAccommodationsOffered.length > 0,
                'Dietary accommodations',
                dietaryAccommodationsOffered,
                true
              )}{' '}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
    </Fragment>
  );
};

export default BakerDetails;
