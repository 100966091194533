import { Fragment } from 'react';

import { BAR_SERVICES_TAXONOMY_KEY } from '@zola-helpers/client/dist/es/marketplace/vendorTaxonomyKeys';

import {
  CouplesStorefrontDetailsBarServices,
  CouplesStorefrontDetailsCaterer,
} from '~/types/storefrontDetails';

import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import ShowcasesSection from '../components/ShowcasesSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { filterForParentKey, serviceBlock } from '../helpers/vendor';
import ShowcaseStorySection from './components/ShowcaseStorySection';

const CatererDetails = () => {
  const { storefrontDetails, otherServices } = useStorefrontDetails<
    CouplesStorefrontDetailsCaterer | CouplesStorefrontDetailsBarServices
  >();
  const { preferredVendors, taxonomyKey, catererStories, name, options, packages } =
    storefrontDetails;
  const catererServicesOffered = filterForParentKey('caterer-general-services', options);
  const cuisineTypesOffered = filterForParentKey('caterers-cuisine-types', options);
  const dietaryRestrictionsOffered = filterForParentKey('caterer-dietary-restrictions', options);
  const menuFormatsOffered = filterForParentKey('menu-formats-offered', options);
  const beverageServicesOffered = filterForParentKey('beverages-related-services', options);
  const drinkTypesOffered = filterForParentKey('caterer-drink-types', options);
  const caterersExtraCosts = filterForParentKey('caterer-extra-costs', options);
  const hasServices =
    [catererServicesOffered, beverageServicesOffered, drinkTypesOffered, caterersExtraCosts].some(
      (services) => services.length > 0
    ) ||
    [cuisineTypesOffered, dietaryRestrictionsOffered, menuFormatsOffered].some(
      (services) => taxonomyKey !== BAR_SERVICES_TAXONOMY_KEY && services.length > 0
    );

  const hasServiceOrPackageOrShowcases =
    hasServices || !!packages?.length || taxonomyKey !== BAR_SERVICES_TAXONOMY_KEY;

  return (
    <Fragment>
      {hasServiceOrPackageOrShowcases && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {taxonomyKey !== BAR_SERVICES_TAXONOMY_KEY && (
            <ShowcasesSection
              showcases={catererStories}
              component={ShowcaseStorySection}
              heading="Sample catered weddings"
            />
          )}
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(
                catererServicesOffered.length > 0,
                'Services',
                catererServicesOffered,
                true
              )}
              {serviceBlock(
                taxonomyKey !== BAR_SERVICES_TAXONOMY_KEY && cuisineTypesOffered.length > 0,
                'Cuisines',
                cuisineTypesOffered,
                true
              )}
              {serviceBlock(
                taxonomyKey !== BAR_SERVICES_TAXONOMY_KEY && dietaryRestrictionsOffered.length > 0,
                'Dietary accommodations',
                dietaryRestrictionsOffered,
                true
              )}
              {serviceBlock(
                taxonomyKey !== BAR_SERVICES_TAXONOMY_KEY && menuFormatsOffered.length > 0,
                'Meal types',
                menuFormatsOffered,
                true
              )}
              {serviceBlock(
                beverageServicesOffered.length > 0,
                'Beverage services',
                beverageServicesOffered,
                true
              )}
              {serviceBlock(drinkTypesOffered.length > 0, 'Drink types', drinkTypesOffered, true)}
              {serviceBlock(caterersExtraCosts.length > 0, 'Extra costs', caterersExtraCosts, true)}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
    </Fragment>
  );
};

export default CatererDetails;
