import { RefObject, createRef, useEffect, useRef, useState, useMemo, Fragment } from 'react';

import { ReviewView } from '@zola/svc-marketplace-ts-types';
import { ChevronLeftIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronLeft';
import { ChevronRightIcon } from '@zola/zola-ui/src/components/SvgIconsV3/ChevronRight';
import { XIcon } from '@zola/zola-ui/src/components/SvgIconsV3/X';
import Portal from '@zola/zola-ui/src/components/util/Portal';
import useClickOutside from '@zola/zola-ui/src/hooks/useClickOutside';
import useEffectOnce from '@zola/zola-ui/src/hooks/useEffectOnce';
import { useScrollLock } from '@zola/zola-ui/src/hooks/useScrollLock/useScrollLock';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import Slider from 'react-slick';

import { HeaderReviewInfo } from '~/components/common/ui/CoverGalleryV2/HeaderReviewInfo';
import { getDaysLeftToResponse } from '~/pages/couples/inquiries/components/FirstMoveExpiration/FirstMoveExpiration';
import { useStorefrontDetailsUnsafe } from '~/pages/couples/storefronts/contexts/StorefrontDetailsContext';
import { MappedReviewView } from '~/types/responseTypes';
import { CAROUSEL_SETTINGS } from '~/util/carouselSettings';

import CachedImage from '../../images/CachedImage';
import { useUnclaimedLearnMoreModal } from '../../modals/UnclaimedLearnMoreModal/UnclaimedLearnMoreModal';
import {
  CaretContainerButton,
  CarouselImage,
  CLOSE_LIGHTBOX_ICON_SIZE,
  CloseLightboxBtn,
  ControlsWrapper,
  DeclineFirstMoveButton,
  ExploreMoreButton,
  IMAGE_PREVIEW_IMAGE_HEIGHT,
  IMAGE_PREVIEW_IMAGE_WIDTH,
  ImagePreviewItem,
  ImagePreviewList,
  ImagePreviewListWrapper,
  ImgContainer,
  Lightbox,
  LightboxImageContainer,
  LightboxInquiryButton,
  ModalContainer,
  ScreenReaderText,
  SlideshowContainer,
  StyledTitle2,
  TitleBar,
  TitleWrapper,
} from './Lightbox.styles';

type NotMobileLightboxProps = {
  photoUuids: string[];
  initialUuid: string;
  onClose: () => void;
  /**
   * Reviews associated with the photos, used to show review info in the title bar
   */
  reviews?: MappedReviewView[] | ReviewView[];

  disableControls?: boolean;
};

const UnclaimedListingButton = ({ closeLightbox }: { closeLightbox: () => void }) => {
  const { showUnclaimedLearnMoreModal } = useUnclaimedLearnMoreModal();
  const { storefrontDetails } = useStorefrontDetailsUnsafe() || {};

  if (!storefrontDetails) {
    return null;
  }

  return (
    <ExploreMoreButton
      variant="primary"
      colorway="cloudAlt"
      onClick={() => {
        closeLightbox();
        showUnclaimedLearnMoreModal(storefrontDetails);
      }}
    >
      Explore more
    </ExploreMoreButton>
  );
};

const Header = ({
  onClose,
  openPhotoUuid,
  openPhotoReview,
  disableControls = false,
}: {
  onClose: () => void;
  openPhotoUuid: string | null;
  openPhotoReview: MappedReviewView | ReviewView | null;
  disableControls?: boolean;
}) => {
  // We use the "unsafe" hook because the lightbox is used in vendor management, where it doesn't
  // make sense to wrap with the couple's storefront details provider
  const { storefrontDetails, firstMove, isUnclaimed, dismissFirstMove } =
    useStorefrontDetailsUnsafe() || {};

  const hed = useMemo(() => {
    if (openPhotoUuid && openPhotoReview) {
      return openPhotoReview.title;
    }
    if (storefrontDetails && firstMove) {
      return `${storefrontDetails.name} has invited you to inquire`;
    }
    if (storefrontDetails) {
      return storefrontDetails.name;
    }
    return null;
  }, [openPhotoUuid, openPhotoReview, storefrontDetails, firstMove]);

  const dek = useMemo(() => {
    return firstMove ? getDaysLeftToResponse(firstMove) : null;
  }, [firstMove]);

  return (
    <TitleBar>
      <TitleWrapper>
        <StyledTitle2 presentation="h6" color="WHITE_100">
          {hed}
        </StyledTitle2>
        {dek && <P.BodySmall color="WHITE_100">{dek}</P.BodySmall>}
        <HeaderReviewInfo openPhotoReview={openPhotoReview} />
      </TitleWrapper>
      <ControlsWrapper>
        {!disableControls && (
          <Fragment>
            {firstMove && (
              <DeclineFirstMoveButton
                variant="secondary"
                colorway="cloudAlt"
                onClick={() => {
                  dismissFirstMove?.();
                  onClose();
                }}
              >
                No thanks
              </DeclineFirstMoveButton>
            )}
            {storefrontDetails && !isUnclaimed && (
              <LightboxInquiryButton
                section="LIGHTBOX"
                position={1}
                data-testid="lightbox-inquiry-button"
                firstMoveUuid={firstMove?.uuid}
                variant="primary"
                colorway="cloudAlt"
                onClick={onClose}
              />
            )}
          </Fragment>
        )}
        {storefrontDetails && isUnclaimed && <UnclaimedListingButton closeLightbox={onClose} />}
        <CloseLightboxBtn onClick={onClose}>
          <XIcon height={CLOSE_LIGHTBOX_ICON_SIZE} width={CLOSE_LIGHTBOX_ICON_SIZE} />
          <ScreenReaderText>Close</ScreenReaderText>
        </CloseLightboxBtn>
      </ControlsWrapper>
    </TitleBar>
  );
};

/**
 * Lightbox for viewing storefront cover/main photos or review photos on desktop and tablet
 */
const NotMobileLightbox = ({
  photoUuids,
  initialUuid,
  onClose,
  reviews = [],
  disableControls = false,
}: NotMobileLightboxProps) => {
  let initialPhotoIndex = photoUuids.findIndex((photoUuid) => photoUuid === initialUuid);

  if (initialPhotoIndex === -1) {
    initialPhotoIndex = 0;
  }

  const [portalContainer, setPortalContainer] = useState<HTMLElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const sliderRef = useRef<Slider | null>(null);
  const previewListRef = useRef<HTMLUListElement | null>(null);
  const [previewRefs, setPreviewRefs] = useState<RefObject<HTMLSpanElement>[]>([]);

  const [openPhotoUuid, setOpenPhotoUuid] = useState<string | null>(
    photoUuids[initialPhotoIndex] || null
  );
  const currentPhotoIndex = useMemo(() => {
    return photoUuids.findIndex((photoUuid) => photoUuid === openPhotoUuid);
  }, [openPhotoUuid, photoUuids]);
  const openPhotoReview = useMemo(() => {
    if (!openPhotoUuid) {
      return null;
    }
    // @ts-ignore TS can't figure out what the `reviews` type is for some reason
    return reviews.find((review) => review.reviewImageIds?.includes(openPhotoUuid)) || null;
  }, [openPhotoUuid, reviews]);

  const { onDisableLock, onEnableLock } = useScrollLock();

  useEffect(() => {
    const scrollY = window.scrollY;
    window.scrollTo(0, 0); // Need to scroll to top when lightbox is opened
    onEnableLock();

    return () => {
      onDisableLock();
      window.scrollTo(0, scrollY); // Scroll back to position before opening lightbox
    };
  }, [onDisableLock, onEnableLock]);

  useEffectOnce(() => {
    if (document) {
      const pageContainer = document.getElementById('root');
      if (pageContainer) {
        setPortalContainer(pageContainer);
      }
    }
  });

  const handleCloseLightbox = () => {
    onClose();
    setOpenPhotoUuid(null);
    document.body.classList.remove('no-scroll');
  };

  useClickOutside({ ref: modalRef, isOpen: !!openPhotoUuid, onClickOutside: handleCloseLightbox });

  const handleKeyPress = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      handleCloseLightbox();
    }
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      e.stopPropagation();
      sliderRef.current?.slickPrev();
    }
    if (e.key === 'ArrowRight') {
      e.preventDefault();
      e.stopPropagation();
      sliderRef.current?.slickNext();
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress, true);

    return function removeEventListenerAndReturnFocus() {
      window.removeEventListener('keydown', handleKeyPress, true);
    };
  });

  /**
   * Finds position of horizontal scrollbar and sets styles if not at beginning
   */
  useEffect(() => {
    const firstListItem = previewRefs?.[0]?.current;
    const lastListItem = previewRefs?.[previewRefs.length - 1]?.current;
    const container = previewListRef?.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((e) => {
          const scrimClass = e.target === firstListItem ? 'leftScrim' : 'rightScrim';
          container?.classList.toggle(scrimClass, e.intersectionRatio < 1);
        });
      },
      { threshold: 1 }
    );
    if (firstListItem && lastListItem) {
      observer.observe(firstListItem);
      observer.observe(lastListItem);
    }

    return () => {
      observer.disconnect();
    };
  }, [previewRefs]);

  useEffectOnce(() => {
    setPreviewRefs(photoUuids.map(() => createRef()));
  });

  if (!openPhotoUuid || portalContainer === null) return null;

  return (
    <Portal portalContainer={portalContainer}>
      <Lightbox onClick={() => null} device="notMobile">
        <LightboxImageContainer ref={containerRef} tabIndex={-1}>
          <ModalContainer ref={modalRef}>
            <Header
              onClose={onClose}
              openPhotoUuid={openPhotoUuid}
              openPhotoReview={openPhotoReview}
              disableControls={disableControls}
            />
            {/* Photo Carousel */}
            <SlideshowContainer>
              <Slider
                {...CAROUSEL_SETTINGS}
                lazyLoad="anticipated"
                initialSlide={initialPhotoIndex}
                appendDots={(dots) => (
                  <ImagePreviewListWrapper>
                    <ImagePreviewList ref={previewListRef}>{dots}</ImagePreviewList>
                  </ImagePreviewListWrapper>
                )}
                onReInit={() => {
                  const previewRef = previewRefs[currentPhotoIndex];
                  previewRef.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                  });
                }}
                beforeChange={(_, i) => {
                  setOpenPhotoUuid(photoUuids[i]);
                }}
                customPaging={(i: number) => {
                  const uuid = photoUuids[i];
                  const ref = previewRefs[i] || createRef();
                  return (
                    <ImagePreviewItem ref={ref}>
                      <CachedImage
                        uuid={uuid}
                        srcSizes={[
                          { width: IMAGE_PREVIEW_IMAGE_WIDTH, height: IMAGE_PREVIEW_IMAGE_HEIGHT },
                        ]}
                        sizes={`${IMAGE_PREVIEW_IMAGE_WIDTH}px`}
                        fit="crop"
                        quality={60}
                      />
                    </ImagePreviewItem>
                  );
                }}
                ref={sliderRef}
                nextArrow={
                  <CaretContainerButton role="button" data-testid="arrow-btn" position="right">
                    <ChevronRightIcon
                      height={CLOSE_LIGHTBOX_ICON_SIZE}
                      width={CLOSE_LIGHTBOX_ICON_SIZE}
                    />
                  </CaretContainerButton>
                }
                prevArrow={
                  <CaretContainerButton role="button" data-testid="arrow-btn" position="left">
                    <ChevronLeftIcon
                      height={CLOSE_LIGHTBOX_ICON_SIZE}
                      width={CLOSE_LIGHTBOX_ICON_SIZE}
                    />
                  </CaretContainerButton>
                }
              >
                {photoUuids.map((uuid) => (
                  <ImgContainer id={`photo-${uuid}`} key={uuid}>
                    <CarouselImage
                      uuid={uuid}
                      srcSizes={[
                        { width: 320, height: 320 },
                        { width: 640, height: 640 },
                        { width: 750, height: 750 },
                        { width: 1000, height: 750 }, // 4:3
                        { width: 1050, height: 750 }, // 7:5
                        { width: 1125, height: 750 }, // 3:2
                        { width: 1333, height: 750 }, // 16:9
                        { width: 1392, height: 750 },
                      ]}
                      sizes="100vw"
                      fit="max"
                      quality={80}
                    />
                  </ImgContainer>
                ))}
              </Slider>
            </SlideshowContainer>
          </ModalContainer>
        </LightboxImageContainer>
      </Lightbox>
    </Portal>
  );
};

export default NotMobileLightbox;
