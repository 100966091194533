import { Fragment } from 'react';

import { CouplesStorefrontDetailsBeautician } from '~/types/storefrontDetails';

import FaqsSection from '../components/FaqsSection';
import JumpMenuSection, { JumpMenuSectionIds } from '../components/JumpMenuSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import PackagesSection from '../components/PackagesSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import VendorStorefrontSection from '../components/VendorStorefrontSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import { serviceBlock } from '../helpers/vendor';

const BeauticianDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsBeautician>();
  const { preferredVendors, name, options, packages } = storefrontDetails;
  const serviceTypesOffered = options.filter((option) =>
    option.key.startsWith('hair-beauty-service-category')
  );
  const servicesOffered = options.filter((option) =>
    // The new facets include any key that begins with beauty-services
    option.key.startsWith('beauty-services')
  );
  const stylesOffered = options.filter((option) => option.key.startsWith('hair-beauty-style'));
  const hasServices = [serviceTypesOffered, servicesOffered, stylesOffered].some(
    (services) => services.length > 0
  );

  const hasServiceOrPackage = hasServices || !!packages?.length;

  return (
    <Fragment>
      {hasServiceOrPackage && (
        <JumpMenuSection sectionName={JumpMenuSectionIds.ServicesPricing}>
          {hasServices && (
            <VendorStorefrontSection hed="Services" className="container">
              {serviceBlock(
                serviceTypesOffered.length > 0,
                'Service categories',
                serviceTypesOffered,
                true
              )}
              {serviceBlock(
                servicesOffered.length > 0,
                'Services & specialties',
                servicesOffered,
                true
              )}
              {serviceBlock(stylesOffered.length > 0, 'Specialty styles', stylesOffered, true)}
            </VendorStorefrontSection>
          )}
          <PackagesSection />
        </JumpMenuSection>
      )}
      <FaqsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <ReviewsSection />
    </Fragment>
  );
};

export default BeauticianDetails;
