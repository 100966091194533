import { Fragment } from 'react';

import { CouplesStorefrontDetailsWeddingPlanner } from '~/types/storefrontDetails';

import FaqsSection from '../components/FaqsSection';
import OtherVendorsSection from '../components/OtherVendorsSection';
import RealWeddingsSection from '../components/RealWeddingsSection';
import ReviewsSection from '../components/ReviewsSection';
import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import ServicesSection from './PlannerServicesSection';

const PlannerDetails = () => {
  const { storefrontDetails, otherServices } =
    useStorefrontDetails<CouplesStorefrontDetailsWeddingPlanner>();
  const { preferredVendors, name } = storefrontDetails;

  return (
    <Fragment>
      <ServicesSection />
      <ReviewsSection />
      <OtherVendorsSection title={`Preferred vendors of ${name}`} vendors={preferredVendors} />
      <OtherVendorsSection title={`More wedding services from ${name}`} vendors={otherServices} />
      <RealWeddingsSection />
      <FaqsSection />
    </Fragment>
  );
};

export default PlannerDetails;
