import { useCallback, useEffect, useState, useRef } from 'react';

import {
  PagingRequest,
  WeddingCardPaginationView,
  WeddingCardView,
} from '@zola/svc-marketplace-ts-types';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import useContainerDimensions from '@zola/zola-ui/src/hooks/useContainerDimensions';
import H from '@zola/zola-ui/src/typography/Headings';

import _shuffle from 'lodash/shuffle';
import LazyLoad from 'react-lazyload';

import { CardShelf } from '~/components/common/cards/CardShelf/CardShelf';
import WeddingCard from '~/components/common/cards/WeddingCard/WeddingCard';
import { CouplesStorefrontDetails } from '~/types/storefrontDetails';
import ApiService from '~/util/apiService';
import { StandardLogFn } from '~/util/logger';
import {
  TrackingProductLocation,
  TrackingProductType,
  trackProductClicked,
} from '~/util/trackingHelper';

import { useStorefrontDetails } from '../contexts/StorefrontDetailsContext';
import JumpMenuSection, { JumpMenuSectionIds } from './JumpMenuSection';

const RealWeddingsSection = () => {
  const { storefrontDetails } = useStorefrontDetails<CouplesStorefrontDetails>();
  const { uuid: storefrontUuid, name: storefrontName } = storefrontDetails;
  const [weddings, setWeddings] = useState<WeddingCardView[]>([]);
  useEffect(() => {
    ApiService.post<WeddingCardPaginationView, PagingRequest>(
      `/web-marketplace-api/v2/wedding/for-storefront/uuid/${storefrontUuid}`,
      {
        offset: 0,
        limit: 16,
      }
    )
      .then(({ entities: receivedWeddings }) => {
        const shuffledWeddings = _shuffle(receivedWeddings); // Keep it randomized for "freshness"
        setWeddings(shuffledWeddings);
      })
      .catch((error) => {
        return StandardLogFn(error);
      });
  }, [storefrontUuid]);

  const handleClick = useCallback(
    (wedding, position) =>
      trackProductClicked({
        location: TrackingProductLocation.STOREFRONT_DETAIL_PAGE,
        name: wedding.title,
        productId: wedding.uuid,
        position,
        productType: TrackingProductType.WEDDING_ALBUM_CARD,
      }),
    []
  );

  const [cards, setCards] = useState<JSX.Element[]>([]);
  useEffect(() => {
    setCards(
      weddings.map((wedding, index) => (
        <LinkV2
          key={`wedding-${wedding.uuid}`}
          onClick={() => handleClick(wedding, index + 1)}
          href={`/inspiration/wedding/${wedding.slug}`}
          target="_blank"
          rel="noopener"
        >
          <WeddingCard wedding={wedding} />
        </LinkV2>
      ))
    );
  }, [handleClick, weddings]);

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useContainerDimensions(ref);

  // Hide section if nothing to show
  if (weddings.length === 0) return <></>;

  return (
    <div ref={ref}>
      <JumpMenuSection
        className="marketplace__real-weddings-section storefront__section"
        sectionName={JumpMenuSectionIds.RealWeddings}
      >
        <div className="container">
          <hr />
          <H.Title2 presentation="h4" strong>
            Real wedding inspiration featuring {storefrontName}
          </H.Title2>
          <div className="row mt-primary" />
          <LazyLoad height={320} offset={300} once>
            <CardShelf hoverScaleAdjust={false} width={width}>
              {cards}
            </CardShelf>
          </LazyLoad>
        </div>
      </JumpMenuSection>
    </div>
  );
};

export default RealWeddingsSection;

export type UseContainerDimensionResult = {
  height: number;
  width: number;
};
