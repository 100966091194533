import { useEffect, useMemo, useState } from 'react';

import { ButtonV3 } from '@zola/zola-ui/src/components/ButtonV3';
import { Carousel } from '@zola/zola-ui/src/components/Carousel';
import { LinkV2 } from '@zola/zola-ui/src/components/LinkV2';
import H from '@zola/zola-ui/src/typography/Headings';
import P from '@zola/zola-ui/src/typography/Paragraphs';

import LazyLoad from 'react-lazyload';
import Gallery from 'react-photo-gallery';

import FloorPlanIcon from '~/assets/images/StorefrontDetail/floorplan.svg';
import { useModal } from '~/contexts/ModalContext';
import { MappedGalleryPhotoView, VenueSpaceView } from '~/types/responseTypes';
import { CarouselSettings } from '~/types/types';
import { formatRange } from '~/util/rangeUtils';
import { formatWithCommas } from '~/util/textUtils';

import GalleryModal from '../../components/GalleryModal';
import GalleryPhoto, { GalleryImage } from '../../components/GalleryPhoto';

import './spaceDetails.less';

type SpaceDetailsResult = {
  seatedCapacity: string;
  standingCapacity: string;
  squareFeet: string | null;
  spaceOptions: string | null;
  spacePricing: string | null;
};
export const useSpaceDetails = ({ space }: { space: VenueSpaceView }): SpaceDetailsResult => {
  const seatedCapacity = formatCapacity(space.minSeatedCapacity, space.maxSeatedCapacity, 'Seated');
  const standingCapacity = formatCapacity(
    space.minStandingCapacity,
    space.maxStandingCapacity,
    'Standing'
  );

  const squareFeet = (space.squareFeet || 0) > 0 ? formatWithCommas(space.squareFeet) : null;

  const spaceOptions =
    (space.entityFacetViews?.length || 0) > 0
      ? (space.entityFacetViews || []).map((option) => option.name).join(', ')
      : null;

  const spacePricing = useMemo(() => {
    if (space.cost || space.includedInVenueCost) {
      return space.includedInVenueCost ? 'Included in total venue pricing' : space.cost;
    }
    return null;
  }, [space.cost, space.includedInVenueCost]);

  return {
    seatedCapacity,
    standingCapacity,
    squareFeet,
    spaceOptions,
    spacePricing,
  };
};

const reactPhotoGalleryColumns = (containerWidth: number) => {
  let columnCount = 1;
  if (containerWidth >= 400) columnCount = 2; // modified for half screen
  if (containerWidth >= 768) columnCount = 3;
  if (containerWidth >= 992) columnCount = 4;
  return columnCount;
};

const carouselSettings: CarouselSettings = {
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  lazyLoad: true,
  dots: true,
  arrows: false,
};

const formatCapacity = (min: number | null, max: number | null, postfix: string) => {
  if ((max !== null && min === null) || (max !== null && min === max)) {
    return `Up to ${max} ${postfix}`;
  }
  return formatRange(min, max, null, (value) => `${value} ${postfix}`);
};

interface SpaceDetailProps {
  showcase: VenueSpaceView;
}

const SpaceDetail = ({ showcase: space }: SpaceDetailProps) => {
  const gallery = space.venueSpaceGalleryViews;

  const [galleryPhotos, setGalleryPhotos] = useState<MappedGalleryPhotoView[]>([]);
  const [truncatedGalleryPhotos, setTruncatedGalleryPhotos] = useState<GalleryImage[]>([]);
  const [galleryDisplayCount, setGalleryDisplayCount] = useState<number>(0);
  const { showCustomModal } = useModal();

  const openGalleryModal = (data: { index: number }, photoGallery: MappedGalleryPhotoView[]) =>
    showCustomModal(GalleryModal, {
      photoGallery,
      index: data.index,
      hideClose: true,
    });

  useEffect(() => {
    function handleScreenSizeChange() {
      if (!window) {
        return;
      }

      if (window.innerWidth >= 992) {
        setGalleryDisplayCount(6);
      } else {
        setGalleryDisplayCount(2);
      }
    }
    if (!galleryDisplayCount) {
      handleScreenSizeChange();
    }
    window.addEventListener('resize', handleScreenSizeChange);
    return () => {
      window.removeEventListener('resize', handleScreenSizeChange);
    };
  });

  useEffect(() => {
    if (gallery) {
      const adjustedPhotos: GalleryImage[] = gallery.map((photo) => ({
        id: photo.id,
        uuid: photo.uuid,
        height: photo.height,
        width: photo.width,
        src: photo.imageUrl,
        key: photo.uuid,
      }));
      setTruncatedGalleryPhotos(adjustedPhotos.slice(0, galleryDisplayCount));
    }
  }, [gallery, galleryDisplayCount]);

  useEffect(() => {
    const tmpPhotos = [...gallery];
    if (space.floorplanImageUrl) {
      tmpPhotos.push({
        uuid: space.floorplanUuid || '',
        imageId: space.floorplanUuid || '',
        imageUrl: space.floorplanImageUrl,
        height: 0,
        width: 0,
        photoCreditReferenceVendorId: null,
        photoCreditReferenceVendorSlug: null,
        id: 0,
      });
    }
    setGalleryPhotos(tmpPhotos);
  }, [gallery, space.floorplanImageUrl, space.floorplanUuid]);

  const showGallery = gallery && gallery.length > 0;

  const carouselPhotos =
    gallery &&
    gallery.map((photo, idx) => (
      <div onClick={() => openGalleryModal({ index: idx }, galleryPhotos)} key={photo.id}>
        <div className="space__carousel-photo">
          <LazyLoad offset={600}>
            <img
              src={`${photo.imageUrl}?w=600&h=600&fit=clip`}
              alt=""
              className="space__carousel-photo-image"
            />
          </LazyLoad>
        </div>
      </div>
    ));

  const { seatedCapacity, standingCapacity, squareFeet, spaceOptions, spacePricing } =
    useSpaceDetails({ space });

  return (
    <div className="space__details mt-primary" data-testid="space-details">
      <Carousel settings={carouselSettings} content={carouselPhotos} className="visible-xs" />
      <div className="space__metadata">
        <H.TitleMedium3 className="space__name" presentation="h5">
          {space.name}
        </H.TitleMedium3>
        {space.pullQuote && (
          <P.BodyBase className="space__pull-quote font-family-serif mb-secondary">
            {space.pullQuote}
          </P.BodyBase>
        )}
        {space.description && (
          <P.BodySmall className="mb-secondary">{space.description}</P.BodySmall>
        )}
        <div className="space__row mb-secondary">
          {(standingCapacity || seatedCapacity) && (
            <div className="space__column">
              <H.TitleMedium4 className="space__subheader" presentation="h6">
                Guest capacity
              </H.TitleMedium4>
              {seatedCapacity && (
                <P.BodySmall className="mt-quaternary">{seatedCapacity}</P.BodySmall>
              )}
              {standingCapacity && (
                <P.BodySmall className="mt-quaternary">{standingCapacity}</P.BodySmall>
              )}
            </div>
          )}
          {squareFeet ? (
            <div className="space__column">
              <H.TitleMedium4 className="space__subheader" presentation="h6">
                Square footage
              </H.TitleMedium4>
              <P.BodySmall className="mt-quaternary">{squareFeet}</P.BodySmall>
            </div>
          ) : null}
        </div>
        {spaceOptions ? (
          <div className="mb-secondary">
            <H.TitleMedium4 className="space__subheader" presentation="h6">
              Ideal for
            </H.TitleMedium4>
            <P.BodySmall>{spaceOptions}</P.BodySmall>
          </div>
        ) : null}
        {space.guestsFeel && (
          <div className="mb-secondary">
            <H.TitleMedium4 className="space__subheader" presentation="h6">
              Guests often feel this space is
            </H.TitleMedium4>
            <P.BodySmall className="mt-quaternary">&ldquo;{space.guestsFeel}&rdquo;</P.BodySmall>
          </div>
        )}
        {space.included && (
          <div className="space__included mb-secondary">
            <H.TitleMedium4 className="space__subheader" presentation="h6">
              Included in this room:
            </H.TitleMedium4>
            <P.BodySmall className="mt-quaternary">{space.included}</P.BodySmall>
          </div>
        )}
        {spacePricing && (
          <div className="space__included mb-secondary">
            <H.TitleMedium4 className="space__subheader" presentation="h6">
              Room cost
            </H.TitleMedium4>
            <P.BodySmall className="mt-quaternary">{spacePricing}</P.BodySmall>
          </div>
        )}
        {space.floorplanImageUrl && (
          <LinkV2
            role="button"
            onClick={() => openGalleryModal({ index: galleryPhotos.length - 1 }, galleryPhotos)}
            className="space__floorplan-button"
          >
            <img src={FloorPlanIcon} alt="floorplan" className="mr-quaternary" />
            {space.name} floor plan
          </LinkV2>
        )}
      </div>
      {showGallery && (
        <div className="space__gallery hidden-xs">
          <Gallery
            photos={truncatedGalleryPhotos}
            columns={reactPhotoGalleryColumns}
            direction="row"
            onClick={(_e, data) => openGalleryModal(data, galleryPhotos)}
            ImageComponent={GalleryPhoto}
          />
          {gallery.length > galleryDisplayCount && (
            <div className="space__gallery-button-container mt-secondary">
              <ButtonV3
                variant="secondary"
                onClick={() => openGalleryModal({ index: 0 }, galleryPhotos)}
              >
                See full gallery
              </ButtonV3>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SpaceDetail;
