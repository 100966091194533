import { isMobile } from '@zola-helpers/client/dist/es/util/responsive';
import { ReviewView } from '@zola/svc-marketplace-ts-types';

import dynamic from 'next/dynamic';

import { MappedReviewView } from '~/types/responseTypes';

import { LightboxEntry } from './types';

/**
 * Dynamically load either the mobile or desktop lightbox
 */
export const LightboxV2 = ({
  entries,
  initialUuid,
  onClose,
  reviews,
  showMobileFooter,
  disableControls = false,
}: {
  entries: LightboxEntry[];
  initialUuid: string;
  onClose: () => void;
  reviews?: MappedReviewView[] | ReviewView[];
  showMobileFooter?: boolean;
  disableControls?: boolean;
}) => {
  const photoUuids = entries.map((entry) => entry.uuid);
  if (isMobile()) {
    const MobileLightboxV2 = dynamic(() => import('./MobileLightboxV2'), {
      ssr: false,
    });
    return (
      <MobileLightboxV2
        photoUuids={photoUuids}
        initialUuid={initialUuid}
        onClose={onClose}
        reviews={reviews}
        showFooter={showMobileFooter}
        disableControls={disableControls}
      />
    );
  }
  const NotMobileLightboxV2 = dynamic(() => import('./NotMobileLightboxV2'), {
    ssr: false,
  });
  return (
    <NotMobileLightboxV2
      photoUuids={photoUuids}
      initialUuid={initialUuid}
      onClose={onClose}
      reviews={reviews}
      disableControls={disableControls}
    />
  );
};

export default LightboxV2;
